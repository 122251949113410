import logo from './logo.svg';
import './App.css';
import Payment from "./containers/Payment";
import Routes from "./Routes";

function App(props) {
    let {isLoading} = props;
    return (
        <div className="App">
            <Routes/>
            <div id={'confirm-dialog'}></div>

        </div>
    );
}

export default App
