import React, {useState, useEffect} from "react"
import "../../css/common.css"
import moment from "moment";
import {singlePaymentFxn} from "./actions";

const PaymentResponse = () => {
    let [isLoading, setIsLoading] = useState(false)
    let [responseObj, setResponseObj] = useState({})
    useEffect(() => {
        loadPayment()
    }, [])

    let loadPayment = async () => {
        let {pathname} = window.location
        let paymentId = pathname.split('/').pop()
        setIsLoading(true)
        let resp = await singlePaymentFxn({paymentId});
        if (resp && resp.success) {
            setResponseObj(resp.data)
            setIsLoading(false)
        } else {
            window.location.href = '/'
        }
    }

    return (
        <>
            {/* {isLoading ? <div className={'loader_outer_view'}>
                <div className={'loader_inner'}>
                    <div className={'spinnerText'}>
                        Loading
                    </div>
                </div>
            </div> : null}*/}


            <section className={'custom-container'}>
                {responseObj && responseObj._id ?
                    <div className="container" style={{paddingBottom: 50}}>
                        <div className={'card custom-card'}>
                            <div className={'logoBox'}>
                                <img src={'../images/cambie_white_logo.png'}/>
                            </div>
                            {responseObj.response && responseObj.response.receipt_url ? <div className={'downloadBox'}>
                                <a className={'btn btn-success btn-download'} target={'_blank'}
                                   href={responseObj.response.receipt_url}>Download Receipt</a>
                            </div> : ""}
                            <div className={'card-body p1'}>
                                <div className={'statusBox'}>
                                    {responseObj.status == 'succeeded' ? <>
                                            <img src={'../images/correct.png'}/>
                                            <h4 className={'alert-sm success'}>Payment Successful!</h4>
                                        </> :
                                        <>
                                            <img src={'../images/failed.png'}/>
                                            <h4 className={'alert-sm danger'}>Payment Failed</h4>
                                        </>}
                                </div>

                                <table className={'table table-borderless table-striped table-light custom-table'}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <strong>Student Id</strong>
                                        </td>
                                        <td>
                                            {responseObj.studentId}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Name</strong>
                                        </td>
                                        <td>
                                            {responseObj.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Amount</strong>
                                        </td>
                                        <td>
                                            {responseObj.amount}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Reference Id </strong>
                                        </td>
                                        <td>
                                            {responseObj.referenceId}
                                            <div className={'notes'}>
                                                <span>Note</span>: Use this ID for future references
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Status</strong>
                                        </td>
                                        <td className={'capText'}>
                                            {responseObj.status == 'succeeded' ? "Payment Successful!" : "Payment Failed"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Transaction Date Time </strong>
                                        </td>
                                        <td>
                                            {responseObj.transactionTime ? moment(responseObj.transactionTime).format('YYYY-MM-DD hh:mm:ss A') : ""}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <a className={'backBtn'} href={'/'}>
                            Back to Home
                        </a>
                        <br/>
                        <br/>
                        <br/>
                    </div> : null}
            </section>
        </>
    )
}

export default PaymentResponse
