import {addCambiePaymentUrl, payCambiePaymentUrl, singlePaymentUrl, payUrl, updatePaymentApi} from '../apis'
import {customAxios as axios} from '../../../request'

export const addCambiePaymentFxn = async (valData) => {
    let {data} = await axios.post(addCambiePaymentUrl(), valData)
    return data
}

export const payCambiePaymentFxn = async (valData) => {
    let {data} = await axios.post(payCambiePaymentUrl(), valData)
    return data
}
export const singlePaymentFxn = async (valData) => {
    let {data} = await axios.post(singlePaymentUrl(), valData)
    return data
}

export const payFxn = async (valData) => {
    let {data} = await axios.post(payUrl(), valData)
    return data
}

export const updatePaymentFxn = async (valData) => {
    let {data} = await axios.post(updatePaymentApi(), valData)
    return data
}
