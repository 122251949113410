import React, {Suspense} from 'react'
import {
    BrowserRouter as Router, Redirect, Route,
    Switch,
} from 'react-router-dom';
import Payment from "./containers/Payment";
import PaymentResponse from "./containers/Payment/paymentResponse";
import Stripe from "./containers/Stripe";
import Home from "./containers/Home";

const Routes = (props) => {
    return (
        <Router>
            <Switch>
                {/*<Route path="/" component={Stripe} exact/>*/}
                <Route path="/" component={Payment} exact/>
                <Route path="/pay/:id" component={PaymentResponse} exact/>
                {/*<Route path="/" component={Home} exact/>*/}
                {/* <Route path="*" exact>
                    <Redirect to="/onlinepayment"/>
                </Route>*/}
            </Switch>
        </Router>
    )
}

export default Routes
