import {loadStripe} from "@stripe/stripe-js";

export {default as notification} from './Notification'

export const Years = [
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
]
export const Months = [
    {label: "01", value: 1},
    {label: "02", value: 2},
    {label: "03", value: 3},
    {label: "04", value: 4},
    {label: "05", value: 5},
    {label: "06", value: 6},
    {label: "07", value: 7},
    {label: "08", value: 8},
    {label: "09", value: 9},
    {label: "10", value: 10},
    {label: "11", value: 11},
    {label: "12", value: 12},
]

export const StripePromise = loadStripe('pk_live_51P3PD8RrHVePnCaKEm0wz6fXSjiopsRKG3VVFEocUXeZcpPez672FlDBa4oYbX6c07pBTG8ftKj0iJLux7fidafb00MTEBUuj8');
