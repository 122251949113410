import {apiUrl} from '../../../settings'

export const addCambiePaymentUrl = () => {
    return apiUrl + '/api/addCambiePayment'
}
export const payCambiePaymentUrl = () => {
    return apiUrl + '/api/payCambiePayment'
}
export const singlePaymentUrl = () => {
    return apiUrl + '/cambie/singlePayment'
}

export const payUrl = () => {
    return apiUrl + '/cambie/pay'
}

export const updatePaymentApi = () => {
    return apiUrl + '/cambie/updatePayment'
}
