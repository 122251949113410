import axios from 'axios'
import {apiUrl} from './settings'

export const API_URL = apiUrl

export const authAxios = axios.create({
    baseURL: apiUrl
})

export let customAxios = axios.create({})

